import React, { createContext, useContext, useState } from 'react';

const ActiveInputContext = createContext();

export const useActiveInput = () => {
  return useContext(ActiveInputContext);
};

export const ActiveInputProvider = ({ children }) => {
  const [activeInput, setActiveInput] = useState('');

  return (
    <ActiveInputContext.Provider value={{ activeInput, setActiveInput}}>
      {children}
    </ActiveInputContext.Provider>
  );
};