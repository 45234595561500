import { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const CreateLanguage = () => {
  const sessionToken = localStorage.getItem("bearerToken");

  const [language, setLanguage] = useState({
    AlternativeLanguageID: null,
    Code: "",
    Name: "",
    Translations: null,
  });
  const { setActiveInput } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLanguage((prevLanguage) => ({
      ...prevLanguage,
      [name]: value,
    }));
  };

  const createLanguage = () => {
    console.log(language);
    fetch("https://auth.decentra.net/odata/Language", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(language),
    })
      .then((response) => {
        if (response) {
          console.log(response);
          return response.json();
        } else {
          notify("Error creating language")
          throw Error("Failed to create user");
        }
      })
      .then((data) => {
        setActiveInput("languageCreated")
        console.log("Language created:", data);
      })
      .catch((error) => {
        notify("Error creating language")
        console.error("Error creating Language:", error);
      });
  };

  return (
    <div className="m-3 m-3 d-flex align-items-center justify-content-center">
      <div className="w-50">
        <h5 style={{ textAlign: "left" }}>Add new language</h5>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Language Code"
            name="Code"
            value={language.Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={language.Name}
            onChange={handleInputChange}
          />
        </div>

        <button className="btn btn-success w-100" onClick={createLanguage}>
          Create lanugage
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};
