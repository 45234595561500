import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const Languages = () => {
  const api = "https://auth.decentra.net/odata/Language";

  const sessionToken = localStorage.getItem("bearerToken");
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setActiveInput } = useActiveInput();
  const filteredList = filterListByName(languageList, searchString);

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setLanguageList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedLanguage,
        setSelectedLanguage
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedLanguage]);

  const handleSaveLanguage = () => {
    if (selectedLanguage) {
      const patchUrl = `https://auth.decentra.net/odata/Language(${selectedLanguage.ID})`;
      const patchData = {
        Code: selectedLanguage.Code,
        Name: selectedLanguage.Name,
        ID: selectedLanguage.ID,
      };
      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Language updated successfully");
            setActiveInput("languageUpdate")
            fetchData(api, sessionToken).then((data) => {
              setLanguageList(data.value);
            });
          } else {
            notify("Error updating language")
            console.error("Error updating language. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating language")
          console.error("Error updating language:", error);
        });
    }
  };

  const handleChange = (field, value) => {
    setSelectedLanguage((prevLanguage) => ({
      ...prevLanguage,
      [field]: value,
    }));
  };


  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5>Languages</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedLanguage?.ID
                      ? "list-group-item active"
                      : "list-group-item "
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedLanguage(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedLanguage && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Language Details</h5>
              </div>
              <MDBInput
                name="Code"
                id="code"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedLanguage.Code}
                onChange={(e) => handleChange("Code", e.target.value)}
                label="Code"
                type="text"
                size="md"
              />
              <MDBInput
                name="Name"
                id="name"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedLanguage.Name}
                onChange={(e) => handleChange("Name", e.target.value)}

                label="Name"
                type="text"
                size="md"
              />
              <div>
                <button
                  onClick={handleSaveLanguage}
                  className="mb-3 w-100 btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
