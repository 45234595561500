import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchData = async (api, sessionToken) => {
    try {
      const response = await fetch(api, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  export const filterListByName = (list, searchString) => {
    return list.filter((item) =>
      item.Name.toLowerCase().includes(searchString.toLowerCase())
    );
  };
  
  export const handleListNavigation = (
    event,
    filteredList,
    selectedItem,
    setSelectedItem
  ) => {
    const currentIndex = filteredList.findIndex(
      (item) => item.ID === selectedItem?.ID
    );
  
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        const newIndexUp = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        setSelectedItem(filteredList[newIndexUp]);
        break;
      case "ArrowDown":
        event.preventDefault();
        const newIndexDown =
          currentIndex < filteredList.length - 1
            ? currentIndex + 1
            : currentIndex;
        setSelectedItem(filteredList[newIndexDown]);
        break;
      case "Escape":
        setSelectedItem(null);
        break;
      default:
        break;
    }
  };
  
  export const updateDetailData = (api, sessionToken, selectedItem, setList) => {
    if (selectedItem && sessionToken) {
      console.log("Data:", JSON.stringify(selectedItem));
      console.log(`${api}/${selectedItem.id}`);
      fetch(`${api}/${selectedItem.ID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(selectedItem),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Data updated:", data);
          setList((list) =>
            list.map((user) => (user.ID === data.ID ? data : user))
          );
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    } else {
      console.error("No valid session token found.");
    }
  };
  

export const notify = (message) => {
    toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
  };