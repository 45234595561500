import { useEffect, useState } from "react";
import {
  fetchData,
  handleListNavigation,
  filterListByName,
} from "../../Utils/ReusableFunctions";
import "./Country.css";
import { MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const Country = () => {
  const api = "https://auth.decentra.net/odata/Country";
  const sessionToken = localStorage.getItem("bearerToken");
  const { setActiveInput } = useActiveInput();
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchString, setSearchString] = useState("");

  const filteredList = filterListByName(countryList, searchString);

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setCountryList(data.value);
      console.log(data);
    });
  }, [sessionToken]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedCountry,
        setSelectedCountry
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedCountry]);

  const handleSave = () => {
    if (selectedCountry) {
      const patchUrl = `https://auth.decentra.net/odata/Country(${selectedCountry.ID})`;
      const patchData = {
        Code: selectedCountry.Code,
        Name: selectedCountry.Name,
        Number: selectedCountry.Number,
        Alpha3: selectedCountry.Alpha3,
      };
      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Country updated successfully");
            setActiveInput("countryUpdate")
            fetchData(api, sessionToken).then((data) => {
              setCountryList(data.value);
            });
          } else {
            notify("Error updating country")
            console.error("Error updating country. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating country")
          console.error("Error updating country:", error);
        });
    }
  };

  const handleChange = (field, value) => {
    setSelectedCountry((prevCountry) => ({
      ...prevCountry,
      [field]: value,
    }));
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5>Countries</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedCountry?.ID
                      ? "list-group-item active"
                      : "list-group-item "
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedCountry(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedCountry && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Country Details</h5>
              </div>
              <div className="form-floating">
                <MDBInput
                  name="Code"
                  id="Code"
                  wrapperClass="mb-3"
                  className="form-control"
                  value={selectedCountry.Code}
                  onChange={(e) => handleChange("Code", e.target.value)}
                  label="Code"
                  type="text"
                  size="md"
                />
              </div>
              <div className="form-floating">
                <MDBInput
                  name="Name"
                  id="Name"
                  wrapperClass="mb-3"
                  className="form-control"
                  value={selectedCountry.Name}
                  onChange={(e) => handleChange("Name", e.target.value)}
                  label="Name"
                  type="text"
                  size="md"
                />
              </div>
              <div className="form-floating">
                <MDBInput
                  name="Number"
                  id="Number"
                  wrapperClass="mb-3"
                  className="form-control"
                  value={selectedCountry.Number}
                  onChange={(e) => handleChange("Number", e.target.value)}
                  label="Code"
                  type="text"
                  size="md"
                />
              </div>
              <div className="form-floating">
                <MDBInput
                  name="Alpha3"
                  id="Alpha3"
                  wrapperClass="mb-2"
                  className="form-control"
                  value={selectedCountry.Alpha3}
                  onChange={(e) => handleChange("Alpha3", e.target.value)}
                  label="Alpha3"
                  type="text"
                  size="md"
                />
              </div>
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
