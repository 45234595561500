import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const CreateApikey = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [userList, setUserList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const userApi = "https://auth.decentra.net/odata/User";
  const applicationApi = "https://auth.decentra.net/odata/application";
  const [apikey, setApikey] = useState({
    UserID: "",
    ApplicationID: "",
    Key: "",
    UseData: "",
    DeviceID: "",
  });
  const { setActiveInput } = useActiveInput();

  useEffect(() => {
    fetchData(userApi, sessionToken).then((data) => {
      setUserList(data.value);
      console.log(data.value);
    });
    fetchData(applicationApi, sessionToken).then((data) => {
      setApplicationList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApikey((prevLanguage) => ({
      ...prevLanguage,
      [name]: value,
    }));
  };

  const createApiKey = () => {
    console.log(apikey);
    fetch("https://auth.decentra.net/odata/apikey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(apikey),
    })
      .then((response) => {
        if (response) {
          console.log(response);
          return response.json();
        } else {
          notify("Error creating apikey")
          throw Error("Failed to create apikey");
        }
      })
      .then((data) => {
        console.log("Apikey created:", data);
        setActiveInput("apiKeyCreated");
        setApikey({
          UserID: "",
          ApplicationID: "",
          Key: "",
          UseData: "",
          DeviceID: "",
        });
      })
      .catch((error) => {
        notify("Error creating apikey")
        console.error("Error creating apikey:", error);
      });
  };

  const handleUserChange = (event) => {
    setApikey({
      ...apikey,
      UserID: event.target.value,
    });
  };

  const handleApplicationChange = (event) => {
    setApikey({
      ...apikey,
      ApplicationID: event.target.value,
    });
  };

  return (
    <div className="m-3 m-3 d-flex align-items-center justify-content-center">
      <div className="w-50">
        <h5 style={{ textAlign: "left" }}>Create new apikey</h5>
        <div className="mb-2">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleUserChange}
            value={apikey.UserID}
          >
            <option>Choose User</option>
            {userList.map((user) => (
              <option key={user.ID} value={user.ID}>
                {user.Name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-2">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleApplicationChange}
            value={apikey.ApplicationID}
          >
            <option>Choose application</option>
            {applicationList.map((application) => (
              <option key={application.ID} value={application.ID}>
                {application.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group mb-2 ">
          <input
            type="UseData"
            className="form-control"
            placeholder="UseData"
            name="UseData"
            value={apikey.UseData}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-2 ">
          <input
            type="number"
            className="form-control"
            placeholder="DeviceID"
            name="DeviceID"
            value={apikey.DeviceID}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn btn-success w-100" onClick={createApiKey}>
          Create apikey
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};
