import { useState } from "react";
import logo from '../../Images/full_logo.svg'
import './LoginAuth.css'
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { bytesToBase64, generateHash, passwordHash } from "./AuthFunctions";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const LogInAuth = () => {
  const navigate = useNavigate();
  const initState = {
    userID: "",
    password: "",
  };

  const [state, setState] = useState(initState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  async function SignIn() {
    const _nonce = new Uint8Array(16);
    crypto.getRandomValues(_nonce);
    let date = new Date();
    const _date = new TextEncoder().encode(date.toISOString());
    const _passwordHash = await passwordHash(state.password);
    const _password = new TextEncoder().encode(_passwordHash);
    try {
      const passwordDigest = await generateHash(_nonce, _date, _password);
      const userData = {
        Username: state.userID,
        Nonce: bytesToBase64(_nonce),
        TimeStamp: date.toISOString(),
        PasswordDigest: passwordDigest,
      };

      const response = await fetch("https://auth.decentra.net/identity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.status === 200) {
        console.log("Login successful");
        const responseData = await response.json();
        // const userName = responseData.profile.name;
        const sessionToken = responseData.accessToken;
        // localStorage.setItem("userName", userName);
        localStorage.setItem("bearerToken", sessionToken);
        console.log(sessionToken);
        navigate("/applicationcontainer/users");
      } else {
        notify("Error login in")
        console.log("Login failed");
      }
    } catch (error) {
      notify("Error login in")
      console.error("Error:", error);
    }
  }

  return (
    <div className="loginContainer">
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center ">
          <MDBCol col="12">
            <MDBCard
              className="text-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "400px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <h3
                    className="fw-bold mb-2 text-uppercase"
                    style={{ flex: 1, textAlign: "left" }}
                  >
                    Login
                  </h3>
                  <img
                    src={logo}
                    alt="LoginImage"
                    style={{ height: "60px", paddingBottom: 10 }}
                  />
                </div>
                <hr></hr>
                <MDBInput
                  name="userID"
                  wrapperClass="mb-4 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.userID}
                  onChange={handleChange}
                  label="Email address"
                  type="email"
                  size="lg"
                />
                <MDBInput
                  name="password"
                  wrapperClass="mb-4 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.password}
                  onChange={handleChange}
                  label="Password"
                  type="password"
                  size="lg"
                />
                <MDBBtn
                  outline
                  className="mx-2 px-5 w-100 border-white text-white"
                  color="white"
                  onClick={SignIn}
                  size="lg"
                  type="submit"
                >
                  Login
                </MDBBtn>
                <p className="small mb-3 pb-lg-2 mt-5">
                  <a className="text-white-50" href="#!">
                    Forgot password?
                  </a>
                </p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer/>
    </div>
  );
};
