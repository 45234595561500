import { Routes, Route, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Users } from "../User/Users";
import { CreateUser } from "../User/CreateUser";
import { Country } from "../Country/Country";
import { Languages } from "../Language/Languages";
import { CreateLanguage } from "../Language/CreateLanguage";
import { Roles } from "../Roles/Roles";
import { Tenant } from "../Tenant/Tenant";
import { CreateTenant } from "../Tenant/CreateTenant";
import { Application } from "../Application/Application";
import { CreateApplication } from "../Application/CreateApplication";
import { CreateRole } from "../Roles/CreateRole";
import { ApiKeys } from "../ApiKeys/ApiKeys.js";
import { CreateApikey } from "../ApiKeys/CreateApikey.js";

export const SideBar = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(jwtDecode(sessionToken).role);
  }, [sessionToken]);

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div
          className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 d-none d-md-block"
          style={{
            width: "12rem",
            borderRight: "solid",
            borderWidth: "0.1rem",
            borderColor: "lightgrey",
          }}
        >
          <div
            className="d-flex flex-column align-items-sm-start pt-2 min-vh-100"
            id="navbarSupportedContent"
          >
            <ul className="list-group list-group-flush w-100 text-start">
              <li className="list-group-item list-group-item-action">
                <i className="bi bi-person-badge"></i>Brugere
              </li>

              {userRole === "SystemAdmin" ? (
                <li className="list-group-item list-group-item-action">
                  <i className="bi bi-people-fill"></i> Tenant
                </li>
              ) : null}

              <li className="list-group-item list-group-item-action">
                <i className="bi bi-key-fill"></i> API Nogler
              </li>
              <li className="list-group-item list-group-item-action">
                <i className="bi bi-box-fill"></i> Materialer
              </li>
              <li className="list-group-item list-group-item-action">
                <i className="bi bi-buildings-fill"></i>Bygningsdele
              </li>
              <li className="list-group-item list-group-item-action">
                <i className="bi bi-dot"></i> Placering
              </li>
              <li
                className="list-group-item list-group-item-action"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i className="bi bi-clipboard2-data-fill"></i> Analyser{" "}
                <i className="bi bi-chevron-compact-down"></i>
              </li>
              <div className="collapse" id="collapseExample">
                <ul
                  style={{ marginLeft: "2rem" }}
                  className="list-group  list-group-flush"
                >
                  <li className="list-group-item list-group-item-action border-0 ">
                    An item
                  </li>
                  <li className="list-group-item list-group-item-action border-0">
                    A second item
                  </li>
                  <li className="list-group-item list-group-item-action border-0">
                    A third item
                  </li>
                  <li className="list-group-item list-group-item-action border-0">
                    A fourth item
                  </li>
                  <li className="list-group-item list-group-item-action border-0">
                    And a fifth one
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <div className="col py-3">
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="/users" element={<Users />} />
              <Route path="/createuser" element={<CreateUser />} />
              <Route path="/countries" element={<Country />} />
              <Route path="/languages" element={<Languages />} />
              <Route path="/createlanguage" element={<CreateLanguage />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/tenants" element={<Tenant />} />
              <Route path="/createtenants" element={<CreateTenant />} />
              <Route path="/applications" element={<Application />} />
              <Route
                path="/createapplication"
                element={<CreateApplication />}
              />
              <Route path="/createrole" element={<CreateRole />} />
              <Route path="/apikeys" element={<ApiKeys />} />
              <Route path="createapikey" element={<CreateApikey />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};
