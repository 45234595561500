import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { AssignRoleModal } from "./AssignRoleModal";
import { ToastContainer } from "react-toastify";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";


export const Roles = () => {
  const api = "https://auth.decentra.net/odata/ROle";
  const sessionToken = localStorage.getItem("bearerToken");
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setActiveInput } = useActiveInput();

  const notifyError = (errorMessage) => {
    notify(errorMessage);
  };

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setRoleList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = filterListByName(roleList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedRole, setSelectedRole);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedRole]);

  const handleSave = () => {
    if (selectedRole) {
      const patchUrl = `https://auth.decentra.net/odata/role(${selectedRole.ID})`;
      const patchData = {
        Code: selectedRole.Code,
        Name: selectedRole.Name,
        Description: selectedRole.Description,
      };

      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Role updated successfully");
            setActiveInput("roleUpdate")
            fetchData(api, sessionToken).then((data) => {
              setRoleList(data.value);
            });
          } else {
            console.error("Error updating role. Status:", response.status);
            notify("Error updating role")
          }
        })
        .catch((error) => {
          console.error("Error updating role:", error);
          notify("Error updating role")
        });
    }
  };

  const handleChange = (field, value) => {
    setSelectedRole((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5>Roles</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedRole?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedRole(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small key={item.ID}>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedRole && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Role Details</h5>
              </div>
              <MDBInput
                name="Code"
                id="Code"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedRole.Code}
                onChange={(e) => handleChange("Code", e.target.value)}
                label="Code"
                type="text"
                size="md"
              />
              <MDBInput
                name="Name"
                id="Name"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedRole.Name}
                onChange={(e) => handleChange("Name", e.target.value)}
                label="Name"
                type="text"
                size="md"
              />
              <MDBInput
                name="Description"
                id="Description"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedRole.Description}
                onChange={(e) => handleChange("Description", e.target.value)}
                label="Description"
                type="text"
                size="md"
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-1 w-100 btn btn-sm btn-success"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mb-1 w-100 btn btn-sm btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Assign role to user
                </button>
                <AssignRoleModal selectedRole={selectedRole} notifyError={notifyError} />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
