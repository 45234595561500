import React, { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const CreateTenant = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenant, setTenant] = useState({
    Name: "",
  });
  const { setActiveInput } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTenant((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

 const createTenant = () => {
    console.log(tenant);
    fetch("https://auth.decentra.net/odata/tenant", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(tenant),
    })
      .then((response) => {
        if (response) {
          return response.json();
        } else {
          notify("Error to create tenant")
          throw Error("Failed to create tenant");
        }
      })
      .then((data) => {
        console.log("Tenant created:", data);
        setActiveInput("tenantCreated");
        setTenant({
          Name: "",
        });
      })
      .catch((error) => {
        notify("Error create tenant")
        console.error("Error creating tenant:", error);
      });
  };

  return (
    <div className="m-3 m-3 d-flex align-items-center justify-content-center">
      <div className="w-50">
        <h5 style={{ textAlign: "left" }}>Add new tenant</h5>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={tenant.Name}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn btn-success w-100" onClick={createTenant}>
          Add tenant
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};
