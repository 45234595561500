import { Avatar } from "primereact/avatar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { notify } from "../../Utils/ReusableFunctions";

export const NavigationBar = () => {
  //const storedUserName = localStorage.getItem("userName");
  /*  const getInitials = () => {
    return storedUserName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  }; */

  const navigate = useNavigate();
  const location = useLocation();
  
  

  const getButtonLabel = () => {
    switch (location.pathname) {
      case "/applicationcontainer/users":
        return "Add a user";
      case "/applicationcontainer/languages":
        return "Add a language";
      case "/applicationcontainer/roles":
        return "Add a role";
      case "/applicationcontainer/tenants":
        return "Add a tenant";
      case "/applicationcontainer/applications":
        return "Add a application";
      case "/applicationcontainer/apikeys":
        return "Add an api key";
      default:
        return null;
    }
  };

  const handleButtonClick = () => {
    switch (location.pathname) {
      case "/applicationcontainer/users":
        navigate("/applicationcontainer/createuser");
        break;
      case "/applicationcontainer/languages":
        navigate("/applicationcontainer/createlanguage");
        break;
      case "/applicationcontainer/roles":
        navigate("/applicationcontainer/createrole");
        break;
      case "/applicationcontainer/tenants":
        navigate("/applicationcontainer/createtenants");
        break;
      case "/applicationcontainer/applications":
        navigate("/applicationcontainer/createapplication");
        break;
      case "/applicationcontainer/apikeys":
        navigate("/applicationcontainer/createapikey");
        break;
      default:
        break;
    }
  };

  const buttonLabel = getButtonLabel();

const logOut = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  
  if (sessionToken === null) {
    notify("User is already logged out");
    navigate("/");
  } else {
    localStorage.removeItem("bearerToken");
    console.log("User has been logged out");
    navigate("/");
  }
}

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <div className="navbar-brand">eDEdoc</div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <div className="nav-link active" aria-current="page">
                  Home
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">Link</div>
              </li>
            </ul>
            <div style={{ marginLeft: "10px" }} />
            <div style={{ textAlign: "left" }}>
              {buttonLabel !== null && (
                <button className="btn btn-sm" onClick={handleButtonClick}>
                  {buttonLabel}
                </button>
              )}
            </div>
            <ul className="navbar-nav mb-lg-0">
              <li className="nav-item dropdown ">
                <div
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-gear-fill"></i>
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/users"
                    >
                      Brugere
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/countries"
                    >
                      Countries
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/languages"
                    >
                      Languages
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/roles"
                    >
                      Roles
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/tenants"
                    >
                      Tenants
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/applications"
                    >
                      Applications
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/applicationcontainer/apikeys"
                    >
                      Api Keys
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div style={{ marginLeft: "5px" }} />

            <ul
              className="navbar-nav mb-2 mb-lg-0"
              style={{ textAlign: "left" }}
            >
              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Avatar
                    label="MW"
                    style={{ backgroundColor: "grey", color: "#ffffff" }}
                    shape="circle"
                  />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <div className="dropdown-item">Profil</div>
                  </li>
                  <hr className="dropdown-divider" />
                  <li>
                    <div className="dropdown-item" onClick={logOut} >Log ud</div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
