import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { AssignApplicationModal } from "./AssignApplicationModal";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const Application = () => {
  const api = "https://auth.decentra.net/odata/application";
  const sessionToken = localStorage.getItem("bearerToken");
  const [applicationList, setApplicationList] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setActiveInput } = useActiveInput();

  const notifyError = (errorMessage) => {
    notify(errorMessage);
  };

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setApplicationList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = filterListByName(applicationList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedApplication,
        setSelectedApplication
      );
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedApplication]);

  const handleSave = () => {
    if (selectedApplication) {
      const patchUrl = `https://auth.decentra.net/odata/application(${selectedApplication.ID})`;
      const patchData = {
        Code: selectedApplication.Code,
        Name: selectedApplication.Name,
        Url: selectedApplication.Description,
      };

      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Application updated successfully");
            setActiveInput("applicationUpdate");
            fetchData(api, sessionToken).then((data) => {
              setApplicationList(data.value);
            });
          } else {
            notify("Error updating application");
            console.error(
              "Error updating application. Status:",
              response.status
            );
          }
        })
        .catch((error) => {
          notify("Error updating application");
          console.error("Error updating application:", error);
        });
    }
  };

  const handleChange = (field, value) => {
    setSelectedApplication((prevApplication) => ({
      ...prevApplication,
      [field]: value,
    }));
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5>Applications</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedApplication?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedApplication(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    <small key={item.ID}>{item.Code}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedApplication && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Application Details</h5>
              </div>
              <MDBInput
                name="Code"
                id="Code"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApplication.Code}
                onChange={(e) => handleChange("Code", e.target.value)}
                label="Code"
                type="text"
                size="md"
              />
              <MDBInput
                name="Name"
                id="Name"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApplication.Name}
                onChange={(e) => handleChange("Name", e.target.value)}
                label="Name"
                type="text"
                size="md"
              />
              <MDBInput
                name="Url"
                id="Url"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedApplication.Url}
                onChange={(e) => handleChange("Url", e.target.value)}
                label="Url"
                type="text"
                size="md"
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-1 w-100 btn btn-sm btn-success"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mb-1 w-100 btn btn-sm btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Assign application to user
                </button>
                <AssignApplicationModal
                  selectedApplication={selectedApplication}
                  notifyError={notifyError}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
