import "./App.css";
import { LogInAuth } from "./Components/Authentication/LogInAuth";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { ApplicationContainer } from "./Components/Navigation/ApplicationContainer";
import { PrivateRoute } from "./Components/Navigation/PrivateRoute";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LogInAuth />} />
          <Route path="*" element={<NotFound />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="/applicationcontainer/*"
              element={<ApplicationContainer />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h2>404 - Not Found</h2>
      Go to <Link to="/">Login</Link> page
    </div>
  );
}

export default App;
