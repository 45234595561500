import React, { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const CreateUser = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const api = "https://auth.decentra.net/odata/tenant";
  const [tenantList, setTenantList] = useState([]);
  const [user, setUser] = useState({
    Username: "",
    Active: true,
    TenantID: "",
    Name: "",
  });
  const { setActiveInput } = useActiveInput();

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setTenantList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const createUser = () => {
    console.log(user);
    fetch("https://auth.decentra.net/odata/User", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        if (response) {
          return response.json();
        } else {
          notify("Error creating user");
          throw Error("Failed to create user");
        }
      })
      .then((data) => {
        console.log("User created:", data);
        setActiveInput("userCreated");
        setUser({
          Username: "",
          Active: true,
          TenantID: "",
          Name: "",
        });
      })
      .catch((error) => {
        notify("Error creating user");
        console.error("Error creating user:", error);
      });
  };

  return (
    <div className="m-3 m-3 d-flex align-items-center justify-content-center">
      <div className="w-50">
        <h5 style={{ textAlign: "left" }}>Add new user</h5>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="UserID"
            name="Username"
            value={user.Username}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={user.Name}
            onChange={handleInputChange}
          />
        </div>

        <select
          className="form-select mb-3"
          name="TenantID"
          value={user.TenantID}
          onChange={handleInputChange}
        >
          <option> Choose tenant </option>
          {tenantList.map((tenant) => (
            <option key={tenant.ID} value={tenant.ID}>
              {tenant.Name}
            </option>
          ))}
        </select>

        <button className="btn btn-success w-100" onClick={createUser}>
          Create User
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};
