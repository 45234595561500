import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const Tenant = () => {
    const api =
    "https://auth.decentra.net/odata/tenant";
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenantList, setTenantList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setActiveInput } = useActiveInput();


  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setTenantList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = filterListByName(tenantList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedTenant, setSelectedTenant);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedTenant]);

  const handleSave = () => {
    if (selectedTenant) {
      const patchUrl = `https://auth.decentra.net/odata/tenant(${selectedTenant.ID})`;
      const patchData = {
        Name: selectedTenant.Name,
      };

      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Tenant updated successfully");
            setActiveInput("tenantUpdate")
            fetchData(api, sessionToken).then((data) => {
              setTenantList(data.value);
            });
          } else {
            notify("Error updating tenant")
            console.error("Error updating tenant. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating tenant")
          console.error("Error updating tenant:", error);
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5>Tenants</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedTenant?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedTenant(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedTenant && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>Tenant Details</h5>
              </div>
              <MDBInput
                name="Name"
                id="Name"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedTenant.Name}
                onChange={(e) => {
                  setSelectedTenant((prevTenant) => ({
                    ...prevTenant,
                    Name: e.target.value,
                  }));
                }}
                label="Name"
                type="Name"
                size="md"
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn-sm btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};