import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
  notify,
} from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { MDBInput } from "mdb-react-ui-kit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const Users = () => {
  const api =
    "https://auth.decentra.net/odata/User?$expand=Roles($expand=Role($select=Code))";
  const sessionToken = localStorage.getItem("bearerToken");
  const { setActiveInput } = useActiveInput();
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setUserList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = filterListByName(userList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedUser, setSelectedUser);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedUser]);

  const handleSave = () => {
    if (selectedUser) {
      const patchUrl = `https://auth.decentra.net/odata/User(${selectedUser.ID})`;
      const patchData = {
        Name: selectedUser.Name,
        Username: selectedUser.Username,
        Active: selectedUser.Active,
      };

      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("User updated successfully");
            setActiveInput("userUpdate");
            fetchData(api, sessionToken).then((data) => {
              setUserList(data.value);
            });
          } else {
            notify("Error updating user");
            console.error("Error updating user. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating user");
          notify()
          console.error("Error updating user:", error);
        });
    }
  };

  const handleInputSelect = (inputName, isFocused) => {
    if (isFocused) {
      setActiveInput(inputName);
    } else {
      setActiveInput(null);
    }
  };

  const handleInputChange = (fieldName, value) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value,
    }));
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5>Users</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedUser?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedUser(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.Name}</div>
                    {item.Roles.map((role) => (
                      <small key={role.ID}>{role.Role.Code}</small>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedUser && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>User Details</h5>
              </div>

              <MDBInput
                name="Name"
                id="Name"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedUser.Name}
                onChange={(e) => handleInputChange("Name", e.target.value)}
                onFocus={() => handleInputSelect("name", true)}
                onBlur={() => handleInputSelect("name", false)}
                label="Name"
                type="Name"
                size="md"
              />

              <MDBInput
                name="Username"
                id="email"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedUser.Username}
                onChange={(e) => handleInputChange("Username", e.target.value)}
                label="Email address"
                type="email"
                size="md"
              />
              <div
                className="form-check form-switch text-start mb-2"
                style={{
                  border: "solid",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  borderColor: "#BDBDBD",
                }}
              >
                <div className="m-1">
                  <input
                    name="active"
                    checked={selectedUser.Active}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="active"
                    onChange={(e) =>
                      handleInputChange("Active", e.target.checked)
                    }
                  />
                  <label className="form-check-label" htmlFor="active">
                    {selectedUser.Active ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  className="mb-3 w-100 btn btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
