import React, { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const CreateApplication = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [application, setApplication] = useState({
    Code: "",
    Name: "",
    Url: "",
  });
  const { setActiveInput } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplication((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const createApplication = () => {
    console.log(application);
    fetch("https://auth.decentra.net/odata/application", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(application),
    })
      .then((response) => {
        if (response) {
          return response.json();
        } else {
          notify("Error creating application")
          throw Error("Failed to create application");
        }
      })
      .then((data) => {
        console.log("Application created:", data);
        setActiveInput("applicationCreated")
        setApplication({
          Name: "",
          Code: "",
          Url: ""
        });
      })
      .catch((error) => {
        notify("Error creating application")
        console.error("Error creating application:", error);
      });
  };

  return (
    <div className="m-3 m-3 d-flex align-items-center justify-content-center">
      <div className="w-50">
        <h5 style={{ textAlign: "left" }}>Add new application</h5>
        <div className="input-group mb-1 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={application.Name}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-1 ">
          <input
            type="text"
            className="form-control"
            placeholder="Code"
            name="Code"
            value={application.Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-1 ">
          <input
            type="text"
            className="form-control"
            placeholder="Url"
            name="Url"
            value={application.Url}
            onChange={handleInputChange}
          />
        </div>
        <button className="btn btn-success w-100" onClick={createApplication}>
          Add application
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
};
