import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";

export const AssignApplicationModal = ({
  selectedApplication,
  onAssignApplicationError,
  notifyError,
}) => {
  const api = "https://auth.decentra.net/odata/User";
  const sessionToken = localStorage.getItem("bearerToken");
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { setActiveInput } = useActiveInput();

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setUserList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  };

  const handleAssignApplication = () => {
    if (selectedUserId && selectedApplication?.ID) {
      const assignApplicationApi =
        "https://auth.decentra.net/odata/UserApplication";
      const assignData = {
        userId: selectedUserId,
        applicationId: selectedApplication.ID,
      };

      fetch(assignApplicationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      })
        .then((response) => {
          if (response.ok) {
            setActiveInput("applicationAssigned");
            console.log("Application assigned to user successfully");
          } else {
            console.error(
              "Error assigning application to user. Status:",
              response.status
            );
            notifyError("Error assigning application to user");
          }
        })
        .catch((error) => {
          notifyError("Error assigning application to user");
          console.error("Error assigning application to user:", error);
        });
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="staticBackdropLabel">
              Assign application to user
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-2" style={{ textAlign: "left" }}>
              {selectedApplication.Name}
            </div>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleUserChange}
            >
              <option>Choose User</option>
              {userList.map((user) => (
                <option key={user.ID} value={user.ID}>
                  {user.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleAssignApplication}
            >
              Assign application
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
