import { useActiveInput } from "../../Context/ActiveInputTooltip";

export const InfoBarBottom = () => {
  const { activeInput } = useActiveInput();

  const helpText = {
    name: "Enter the user name",
    userUpdate: "User updated successfully",
    userCreated: "User created successfully",
    tenantUpdate: "Tenant updated successfully",
    tenantCreated: "Tenant created successfully",
    roleUpdate: "Role updated successfully",
    roleCreated: "Role created successfully",
    roleAssigned: "Role assigned to user successfully",
    languageCreated: "Language created successfully",
    languageUpdate: "Language updated successfully",
    countryUpdate: "Country updated successfully",
    applicationCreated: "Application created successfully",
    applicationUpdate: "Application updated successfully",
    applicationAssigned: "Application assigned to user successfully",
    apiKeyCreated: "ApiKey created successfully",
    apiKeyUpdate: "ApiKey updated successfully",
  };

  return (
<div>
  <nav className="navbar fixed-bottom navbar-light bg-light">
    <div className="container-fluid">
      <small
        style={{ height: "1rem", fontSize: "0.7rem", marginLeft: "auto" }}
        className="navbar-brand"
      >
        <div>{activeInput ? helpText[activeInput] : null}</div>
      </small>
    </div>
  </nav>
</div>
  );
};
