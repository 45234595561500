import { useState, useEffect } from "react";
import { fetchData, handleListNavigation } from "../../Utils/ReusableFunctions";
import { MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";

export const ApiKeys = () => {
  const api = "https://auth.decentra.net/odata/apikey";
  const sessionToken = localStorage.getItem("bearerToken");
  const [apikeyList, setApikeyList] = useState([]);
  const [selectedApikey, setSelectedApikey] = useState(null);
  const [searchString, setSearchString] = useState("");
  const { setActiveInput } = useActiveInput();

  useEffect(() => {
    fetchData(api, sessionToken).then((data) => {
      setApikeyList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = apikeyList.filter((item) =>
    item.UseData.toLowerCase().includes(searchString.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedApikey,
        setSelectedApikey
      );
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedApikey]);

  const handleSave = () => {
    if (selectedApikey) {
      const patchUrl = `https://auth.decentra.net/odata/apikey(${selectedApikey.ID})`;
      const patchData = {
        UseData: selectedApikey.UseData,
      };
      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Apikey updated successfully");
            setActiveInput("apiKeyUpdate");
            fetchData(api, sessionToken).then((data) => {
              setApikeyList(data.value);
            });
          } else {
            notify("Error updating apikey")
            console.error("Error updating apikey. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating apikey")
          console.error("Error updating apikey:", error);
        });
    }
  };

  const handleChange = (field, value) => {
    setSelectedApikey((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <h5>Api Keys</h5>
            <div>
              <input
                className="form-control me-2 h-50"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setSearchString(e.target.value)}
              />
            </div>
          </div>
          <div className="list-container">
            <div className="list-group small" style={{ textAlign: "left" }}>
              {filteredList.map((item) => (
                <div
                  className={
                    item.ID === selectedApikey?.ID
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  key={item.ID}
                  aria-current="true"
                  onClick={() => setSelectedApikey(item)}
                >
                  <div className="d-flex justify-content-between">
                    <div>{item.UseData}</div>
                    <small key={item.ID}>{item.Key}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {selectedApikey && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                <h5>ApiKeys Details</h5>
              </div>
              <MDBInput
                name="UserID"
                id="UserID"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApikey.UserID}
                label="UserID"
                type="text"
                size="md"
                disabled
              />
              <MDBInput
                name="ApplicationID"
                id="ApplicationID"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApikey.ApplicationID}
                disabled
                label="ApplicationID"
                type="text"
                size="md"
              />
              <MDBInput
                name="Key"
                id="Key"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApikey.Key}
                disabled
                label="Key"
                type="text"
                size="md"
              />
              <MDBInput
                name="UseData"
                id="UseData"
                wrapperClass="mb-3"
                className="form-control"
                value={selectedApikey.UseData}
                onChange={(e) => handleChange("UseData", e.target.value)}
                label="UseData"
                type="text"
                size="md"
              />
              <MDBInput
                name="DevicID"
                id="DevicID"
                wrapperClass="mb-2"
                className="form-control"
                value={selectedApikey.DeviceID}
                disabled
                label="DevicID"
                type="text"
                size="md"
              />
              <div>
                <button
                  onClick={handleSave}
                  className="mb-1 w-100 btn btn-sm btn-success"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
