

export function bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  }
  
  export async function passwordHash(input) {
    const _salt = new TextEncoder().encode("¨c*03+Jbj&s4s");
    const _password = new TextEncoder().encode(input);
    let _buffer = new Uint8Array(_salt.length + _password.length);
    _buffer.set(_salt);
    _buffer.set(_password, _salt.length);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", _buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).toUpperCase().padStart(2, "0"))
      .join("");
    return hash;
  }
  
  export async function generateHash(_nonce, _date, _password) {
    let _buffer = new Uint8Array(
      _nonce.length + _date.length + _password.length
    );
    _buffer.set(_nonce);
    _buffer.set(_date, _nonce.length);
    _buffer.set(_password, _nonce.length + _date.length);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", _buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return bytesToBase64(hashArray);
  }
  